<template lang="pug">
  .dashboard
    h1 DASHBOARD

</template>

<script>
export default {

}
</script>

<style lang="stylus">
  .dashboard
    padding 20px
    text-align initial
</style>
